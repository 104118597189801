export const foodPersonalizedContent = {
    backgroundImg: "foodHomeBg.png",
    arrowImage: "firstArrowFood.png",
    supportImage: "supportImgFood.png",
    simulatorImage: "cozinheira-simulador.png",
    lastArrowImage: "lastArrowFood.png",
    titleText: "Crédito em grupo para quem trabalha com alimentos!",
    titleColor: "#FFFFFF",
    textColor: "#2C2D2C",
    navbarTextColor: "#8EDE63",
    carrousselImg: "carrouselImgFood.png",
    supportText: "Na Veriza temos crédito especial para quem vende produtos alimentícios.",
  };